import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Work = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://sco-capacitacion-y-consultoria.com/facebook-api-proxy.php`
        );
        const data = await response.json();
        setPosts(data.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchData();
  }, []);

  const formatMessage = (message) => {
    const words = message.split(" ");
    const truncatedWords = words.slice(0, 4);
    const truncatedMessage = truncatedWords.join(" ");

    return truncatedMessage;
  };

  const displayedPosts = posts.slice(0, 3);

  return (
    <section className="section min-h-[105vh] mb-16 lg:mb-28" id="work">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-y-12 mb-10 lg:mb-0 lg:grid-cols-2">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className=""
          >
            <div>
              <h2 className="h2 leading-tight text-orange-400">
                Últimas publicaciones
                <br />
                de <span className="text-blue-600">Facebook.</span>
              </h2>
              <p className="max-w-sm mb-16">
                Aquí encontrarás las últimas publicaciones de nuestra página de
                Facebook. 🤳
              </p>
              <a target='_blank'
                rel="noreferrer"
                href="https://www.facebook.com/profile.php?id=100088900876168"
                className="btn btn-gradient rounded-[30px] px-5 py-4 text-center mr-1 mb-1"
              >
                Ver publicaciones
              </a>
            </div>
          </motion.div>
          {displayedPosts.map((post) => (
            <motion.div
              variants={fadeIn("left", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col gap-y-10 "
              key={post.id}
            >
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl lg:w-[500px] h-[300px] w-[350px] bg-white/50">
                <div className="group-hover:bg-black/70 absolute z-40 transition-all duration-300 w-full h-full"></div>
                <img
                  className="group-hover:scale-125 transition-all duration-500 object-cover"
                  src={
                    post.full_picture ? post.full_picture : "../assets/sco.webp"
                  }
                  alt="post de facebook de sco consultores"
                />
                <div className="absolute -bottom-full left-12 group-hover:bottom-36 transition-all duration-500 z-40">
                  <span className="text-gradient">SCO Consultores</span>
                </div>
                <div className="absolute -bottom-full left-12 group-hover:bottom-20 transition-all duration-700 z-40">
                  <span className="text-2xl text-white">
                    {post.message ? formatMessage(post.message) + "..." : ""}
                  </span>
                </div>
                <div className="absolute -bottom-full left-12 group-hover:bottom-4 transition-all duration-700 z-40">
                  <a
                    href={post.permalink_url}
                    target='_blank'
                    rel="noreferrer"
                    type="button"
                    className=" text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-[30px] px-5 py-2 text-center mr-1 mb-1"
                  >
                    Ir a la publicación
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Work;
