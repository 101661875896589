import React from 'react'

import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          <motion.div 
          variants={fadeIn('right', 0.6)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'>

          </motion.div>
          <motion.div 
           variants={fadeIn('left', 0.5)}
           initial='hidden'
           whileInView={'show'}
           viewport={{ once: false, amount: 0.3 }}
          className='flex-1 '>
            <h2 className='h2 text-orange-400'>Sobre nosotros</h2>
            <h3 className='text-[25px] mb-4'>
            SCO Consultores se creó con la finalidad de fortalecer el conocimiento en materia de Seguridad e Higiene, sensibilizar y promover una cultura de prevención de riesgos laborales en el entorno laboral y educativo.
            </h3>
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div className='flex-1'>
                <div className='text-[20px] font-tertiary text-gradient mb-2'>
                  Nuestra visión
                </div>
                <div className='font-primary text-sm tracking-[2px] '>
                Ser un referente para nuestros clientes debido a nuestra calidad en el servicio, nuestra vinculación con sus objetivos, nuestro compromiso y nuestra resolución profesional de sus necesidades.
                </div>
              </div>
              <div className='flex-1'>
                <div className='text-[20px] font-tertiary text-gradient mb-2'>
                Nuestra misión
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                Aportar soluciones reales a las necesidades de empresas, negocios y centros educativos en materia de Seguridad e Higiene, impulsando su crecimiento y beneficio empresarial, además de crear un entorno apto para la realización de sus actividades.
                </div>
              </div>
            </div>
      
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
