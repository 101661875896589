import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const services = [
  {
    name: "Capacitaciones",
    description:
      "Primeros Auxilios, Brigada contra incendio, Comision de seguridad e higiene,Operacion segura de montacargas,Equipo de protección personal,Señalización: colores y señales de seguridad e higiene,NOM-009-STPS-2011,NOM-035-STPS-2018, Combate y prevención de incendios,Formación de instructores, Técnico en urgencias medicas, Supervisor de seguridad y salud ocupacional,Trabajos en altura NOM-009-STPS-2011,Primeros auxilios. RCP y OEA estándar,Manejo básico de extintores,NOM-001-STPS edificios, locales, instalaciones y áreas en los centros de trabajo- condiciones de seguridad,NOM-004-STPS sistema de protección y dispositivos de seguridad en la maquinaria y equipo que se utilice en los centros de trabajo,Llenado del formato de registro y atención prehospitalaria y manejo del sistema SEC-DER-FRAP, NOM-018-STPS sistema armonizado para la identificación y comunicación de peligrosy riesgos por sustancias quimicas peligrosas en los centros de trabajo",
  },
  {
    name: "Consultorías ",
    description:
      "Inspecciones STPS y Medio ambiente",
  },


];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div 
           variants={fadeIn('right', 0.3)}
           initial='hidden'
           whileInView={'show'}
           viewport={{ once: false, amount: 0.3 }}
          className="flex1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0">
            <h2 className="h2 text-orange-400 mb-6">Te ofrecemos</h2>
            <h3 className="h3 max-w-[455px] mb-16">
              Capacitaciones y consultorías en
            </h3>
            <a href="https://drive.google.com/file/d/1GwoIq4yXP6e1p_FzCVtcIQU0hv5zvILQ/view?usp=sharing" target="_blank"><button className="btn btn-sm">Flayer</button></a>
          </motion.div>
          <motion.div 
           variants={fadeIn('left', 0.5)}
           initial='hidden'
           whileInView={'show'}
           viewport={{ once: false, amount: 0.3 }}
          className="flex-1">
            <div>
              {services.map((services, index) => {
                const { name, description, link } = services;

                return (
                  <div
                    className="border-b border-white/20 lg:h-[305px] h-250 mb-[20px] flex"
                    key={index}
                  >
                    <div className="max-w-[850px]">
                      <h4 className="text-[20px] tracking-wider font-primary font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight">
                        {description}
                      </p>
                    </div>
            
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
