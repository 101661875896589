import React from 'react';
import Logo from '../assets/logo.svg';
const Header = () => {
  return (
    <header className="py-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center"> 
       
          <img src={Logo} alt="logo de sco consultores" />
        
      </div>
      </div>
    </header>
  )
};

export default Header;
