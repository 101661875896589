import React from 'react';
import Image from '../assets/avatar.svg';
import { FaWhatsapp, FaFacebook, FaFacebookMessenger } from 'react-icons/fa';

import { TypeAnimation } from 'react-type-animation';

import { motion } from 'framer-motion'
import { fadeIn } from '../variants'

const Banner = () => {
  return(
    <section className='min-h-[80vh] lg:min-h[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-5 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1 variants={fadeIn('up', 0.3)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }}  className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>SCO</motion.h1>
            <motion.div variants={fadeIn('up', 0.4)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }}  className='mb-6 text-[30px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-4'>Ofrecemos</span>
              <TypeAnimation sequence={[ 
                'Capacitaciones',
                3000,
                'Consultorías',
                3000,
              ]} 
              speed={50}
              className='text-orange-400'
              wrapper='span'
              repeat={Infinity}/>
              
              </motion.div>
              <motion.p variants={fadeIn('up', 0.5)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='mb-8 max-w-lg mx-auto lg:mx-0'>Nos dedicamos a brindar servicios especializados en materia de seguridad e higiene para empresas y organizaciones de diversos sectores.
              </motion.p>
              <motion.div variants={fadeIn('up', 0.6)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='flex max-w-max gap-x-6 items-center mb-9 mx-auto lg:mx-0'>
                <a target='_blank' href="https://api.whatsapp.com/send?phone=+525519754399&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20capacitaciones%20y%20consultor%C3%ADas." className="btn btn-gradient rounded-[30px] px-9 py-3 text-center mr-1 mb-1 text-base">Contáctanos</a>
              </motion.div>
              <motion.div variants={fadeIn('right', 0.7)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }}  className='flex text-[30px] gap-x-6 max-w-max mx-auto lg:mx-0'>
                <a target='_blank' href="https://api.whatsapp.com/send?phone=+525519754399&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20capacitaciones%20y%20consultor%C3%ADas."><FaWhatsapp/></a>
                <a target='_blank' href="https://www.facebook.com/profile.php?id=100088900876168"><FaFacebook/></a>
                <a target='_blank' href="https://m.me/103707792609451"><FaFacebookMessenger/></a>
              </motion.div>
          </div>
          <motion.div variants={fadeIn('down', 0.5)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
            <img src={Image} alt='Capacitación en UAEH Tepeji (ESTe) impartida a alumnos'/>
          </motion.div>
        </div>
      </div>
    </section>
  )
};

export default Banner;
